<template>
  <v-app>
    <template v-if="$route.name === 'display' || $route.name === 'activation'">
      <router-view class="mt-7" />
    </template>
    <template v-else-if="$route.name === 'label.print'">
      <router-view />
    </template>
    <template v-else>
      <nav-bar />
      <keep-alive max="2">
        <router-view
          v-if="$route.name === 'index'"
          class="mt-7"
          :key="componentKey"
        />
        <router-view v-else class="mt-7" @needUpdate="forceRerender" />
      </keep-alive>
      <footer-bar />
    </template>
    <div id="snackbar" style="z-index: 9999" />
  </v-app>
</template>

<script>
// @ is an alias to /src
import NavBar from "@/components/NavBar";
import FooterBar from "@/components/FooterBar";

export default {
  name: "home",
  components: {
    FooterBar,
    NavBar,
  },
  data() {
    return {
      componentKey: 0,
    };
  },
  methods: {
    forceRerender() {
      this.componentKey += 1;
    },
  },
};
</script>
