import moment from "moment";
// import axios from "axios";
import SnackBar from "@/components/SnackBar";

export default {
  install(Vue) {
    Vue.mixin({
      created() {},
      methods: {
        $snackbar(props) {
          var ComponentClass = Vue.extend(SnackBar);
          var instance = new ComponentClass({
            propsData: {
              multiLine: props.multiLine || false,
              snackbar: props.snackbar || true,
              toast: props.toast || false,
              text: props.text || "Success",
              timeout: props.timeout || 3000,
              color: props.color || "success",
              absolute: props.absolute || true,
              right: props.right || true,
              top: props.top || false,
            },
          });
          instance.$mount(); // pass nothing
          // this.$refs.container.appendChild(instance.$el)
          document.getElementById("snackbar").appendChild(instance.$el);
        },
        is_mobile() {
          if (
            /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
              navigator.userAgent
            )
          ) {
            return true;
          } else {
            return false;
          }
        },
        calculateAge: function (date) {
          // console.log(this.datepicker);
          let ageMS =
            Date.parse(Date()) -
            Date.parse(date);
          let age = new Date();
          age.setTime(ageMS);
          let ageYear = age.getFullYear() - 1970;
          let ageMonth = age.getMonth(); // Accurate calculation of the month part of the age
          let ageDay = age.getDate(); // Approximate calculation of the day part of the age
          return ageYear < 1
            ? ` (${ageMonth == 0 ? "" : ageMonth + "bl "}${ageDay}hr)`
            : ageYear >= 1 && ageYear <= 3
            ? ` (${ageYear}th ${
                ageMonth == 0 ? "" : ageMonth + "bl "
              }${ageDay}hr)`
            : ` (${ageYear}th)`;
        },
        formatDate: function (date, format1, format2) {
          moment.locale("id");
          if (date) {
            return moment(date, format2 || ["DD-MM-YYYY", "YYYY-MM-DD", "x"], 'id').format(
              format1 || "DD MMM YYYY"
            );
          } else {
            return null;
          }
        },
				trackEvent(category, action, label, value) {
					this.$gtm.trackEvent({
						event: 'grow-app', // Event type [default = 'interaction'] (Optional)
						category: category || "RS ID-GROW",
						action: action || "click",
						label: label || "Label Pasien",
						value: value || 1,
						// noninteraction: false, // Optional
					});
				},
        // async apiPasien(no_rm) {
        //   let url = this.$store.state.settings.server_url || "http://localhost:3030"
        //   let response = await axios.get(url + '/api-pasien/?no_rm=' + no_rm);
        //   // API Binse
        //   // let data = {
        //   //   "no_rm": response.data.response['No. RM'],
        //   //   "nama": response.data.response['Nama'],
        //   //   "ktp": response.data.response['NIK'],
        //   //   "kelamin": (response.data.response['JenKel'] == 'P') ? 'Perempuan' : 'Laki-laki',
        //   //   "tgl_lahir": this.formatDate(response.data.response['Tgl Lahir'], 'MM-DD-YYYY', 'YYYY-MM-DD'),
        //   //   "tempat_lahir": response.data.response['Tmp Lahir'],
        //   //   "alamat": response.data.response['Alamat'],
        //   //   "resep": []
        //   // }
        //   // API Subandi
        //   // let data = {
        //   //   "no_rm": response.data.response.no_rm,
        //   //   "nama": response.data.response.nama,
        //   //   "kelamin": (response.data.response.jenkel == 'P') ? 'Perempuan' : 'Laki-laki',
        //   //   "tgl_lahir": response.data.response.tgl_lahir,
        //   //   "resep": []
        //   // }
        //   // API UMM
        //   let data = {
        //     "no_rm": response.data[0].kode_rm,
        //     "nama": response.data[0].nama_pasien,
        //     "kelamin": response.data[0].jenis_kelamin,
        //     "tgl_lahir": response.data[0].tanggal_lahir,
        //     "alamat": response.data[0].alamat,
        //     "resep": []
        //   }
        //   console.log(data);
        //   return data;
        // },
      },
    });
  },
};
