<template>
    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        :timeout="timeout"
        :right="toast ? false : right"
        :top="toast ? true : top"
        :rounded="toast && 'pill'"
        style="bottom:30px;"
        :color="toast ? color : ''"
        :content-class="toast ? 'text-center' : ''"
    >
        {{ text }}

        <template v-if="!toast" v-slot:action="{ attrs }">
            <v-btn :color="color" text v-bind="attrs" @click="snackbar = false">
                Close
            </v-btn>
        </template>
    </v-snackbar>
</template>
<script>
export default {
    props: {
        multiLine: {
            default: false,
        },
        snackbar: {
            default: false,
        },
        text: {
            default: "Success",
        },
        toast: {
            default: false,
        },
        timeout: {
            default: 2000,
        },
        color: {
            default: "success",
        },
        right: {
            default: false,
        },
        top: {
            default: false,
        },
    },
};
</script>
