<template>
  <div>
    <v-app-bar app :height="is_mobile() ? 49.133858268 : 70" color="white">
      <a
        style="position: absolute; left: 0"
        v-if="backLink"
        @click="$router.go(-1)"
      >
        <v-icon x-large>mdi-chevron-left</v-icon>
      </a>
      <v-speed-dial
        :style="!backLink ? 'position:absolute;left:10px;' : ''"
        left
        transition="slide-y-transition"
        direction="bottom"
      >
        <template v-slot:activator>
          <a
            ><v-img
              class="btn-icon"
              src="@/assets/images/icon.png"
              :max-height="is_mobile() ? 40 : 50"
              :max-width="is_mobile() ? 40 : 50"
              contain
            ></v-img
          ></a>
        </template>
        <v-btn fab dark small color="orange" to="/setting">
          <v-icon>mdi-cog</v-icon>
        </v-btn>
        <v-btn fab dark small color="indigo" @click="restore()">
          <v-icon>mdi-backup-restore</v-icon>
        </v-btn>
        <v-btn fab dark small color="red" @click="logout()" v-if="isLogged">
          <v-icon>mdi-minus</v-icon>
        </v-btn>
      </v-speed-dial>
      <v-spacer></v-spacer>
      <div class="text-right mr-3">
        <div
          :class="`font-weight-bold nexa-black text-uppercase ${
            $vuetify.breakpoint.xs && 'mt-0'
          }`"
          style="font-size: 13px; line-height: 1em"
        >
          {{ $store.state.settings.unit }}
        </div>

        <div
          class="d-flex justify-end align-center"
          style="color: #555; font-size: 16px"
        >
          <v-img
            class="mr-3 text-left"
            :src="`${$store.state.settings.server_url || 'http://localhost:3030'}/assets/logo.png`"
            max-height="15"
            max-width="15"
            contain
          ></v-img>
          <span class="nexa-black mt-1 text-uppercase">{{
            $store.getters.app_name
          }}</span>
        </div>

        <div class="font-weight-bold" style="font-size: 12px; line-height: 1em">
          {{ $store.state.settings.staff }}
        </div>
      </div>
      <v-img
        src="@/assets/images/profil.png"
        :max-height="is_mobile() ? 40 : 50"
        :max-width="is_mobile() ? 40 : 50"
        contain
      ></v-img>
    </v-app-bar>

    <v-container
      class=""
      :style="
        is_mobile()
          ? { 'margin-top': '49.133858268px' }
          : { 'margin-top': '70px' }
      "
      style="
        z-index: 2;
        min-width: 100% !important;
        background: #efefef;
        position: fixed;
        margin: 0;
        padding: 0;
      "
    >
      <div
        class="text-center text-uppercase font-weight-bold"
        :style="is_mobile() ? { 'font-size': '7pt' } : { 'font-size': '14px' }"
      >
        <marquee style="padding-top: 5px"
          ><v-img
            class="mr-3"
            style="float: left"
            src="@/assets/images/icon.png"
            :max-height="is_mobile() ? '10' : '14'"
            :max-width="is_mobile() ? '10' : '14'"
            contain
          ></v-img>
          For Sales and Maintenance WA: 081.2655.2644</marquee
        >
      </div>
    </v-container>
    <confirm-dialog ref="restore">
      Apakah anda yakin untuk reset pengaturan?
    </confirm-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import ConfirmDialog from "@/components/ConfirmDialog";

export default {
  name: "NavBar",
  components: { ConfirmDialog },
  data() {
    return {
      backLink: false,
    };
  },
  watch: {
    $route(to, from) {
      if (from.name) {
        this.backLink = true;
      }
      if (this.$route.name == "home") {
        this.backLink = false;
      }
    },
  },
  computed: {
    ...mapGetters(["isLogged"]),
  },
  methods: {
    logout() {
      this.$store.dispatch("logout");
    },
    restore() {
      this.$refs.restore
        .open()
        .then(() => {
          window.localStorage.removeItem('vuex');
          window.location.reload();
        })
        .catch(() => {
          console.log('restore cancel');
        });
    },
  },
};
</script>
<style>
.btn-icon:hover {
  filter: none;
}
.btn-icon {
  max-height: 50px;
  max-width: 50px;
  filter: grayscale(100%);
}
</style>
